import Swiper, { Navigation, Pagination } from 'swiper';
import { exist, onWindowResize, getWindowSize } from '../utils';
import { BREAKPOINTS } from '../utils/constants';
import 'swiper/css';
import 'swiper/scss/pagination';

const footer = (
	sliderSelector = '.js-footer-slider',
	btnNextSelector = '.js-footer-next-button',
	btnPrevSelector = '.js-footer-prev-button',
	paginationSelector = '.js-footer-pagination',
) => {
	Swiper.use([Navigation, Pagination]);

	const $slider = document.querySelector(sliderSelector);
	const $btnNext = document.querySelector(btnNextSelector);
	const $btnPrev = document.querySelector(btnPrevSelector);
	const $pagination = document.querySelector(paginationSelector);

	if (!exist([$slider, $btnNext, $btnPrev, $pagination])) return;
	let footerSwiper;

	const initSwiper = () => {
		return new Swiper($slider, {
			speed: 800,
			loop: true,
			pagination: {
				el: $pagination,
				type: 'bullets',
			},
			navigation: {
				nextEl: $btnNext,
				prevEl: $btnPrev,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				768: {
					slidesPerView: 2,
				},
			},
		});
	};

	onWindowResize(() => {
		if (getWindowSize().windowWidth < BREAKPOINTS.mediaPoint1) {
			if (!footerSwiper) {
				footerSwiper = initSwiper();
			}
		} else {
			if (footerSwiper) {
				footerSwiper.destroy();
				footerSwiper = null;
			}
		}
	});
};

export default footer;
