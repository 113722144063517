import { exist } from 'utils';
import { onWindowScroll } from '../utils/index';

const header = () => {
	const selectors = {
		searchParent: '.js-search-parent',
		searchTrigger: '.js-search-trigger',
		searchInput: '.js-header-input',
		trigger: '.js-header-trigger',
		header: '.js-header',
		menuTrigger: '.js-menu-trigger',
		headerBtn: '.js-header-btn',
		headerBtnClose: '.js-header-btn-close',
		searchBtn: '.js-search-btn',
		searchInputMobile: '.js-header-mobile-input',
		headerBottomInfoBlock: '.header_mobile__bottom',
		searchWrap: '.header_mobile__search_wrap',
		headerBar: '.js-header-bar',
	};

	const classnames = {
		formOpen: 'header__wrapper--open_mod',
		bodyMenuOpen: 'body--menu_open',
		bodyOpenMenuState: 'body--open_menu_state',
		headerScrollState: 'header--scroll_state',
		headerScrollPos: 'header--pos_state',
		bodyScrollState: 'body--scroll_state',
		menuTriggerState: 'header_mobile__trigger--state',
		headerSublistState: 'header_sublist--state',
		headerItem: 'header_mobile__item',
		headerItemState: 'header_mobile__item--state',
		htmlActiveState: 'html--open_menu_state',
		headerBottomInfo: 'header_mobile__bottom--state',
		searchWrapState: 'header_mobile__search_wrap--state',
	};

	const $body = document.body;
	if (!exist($body)) return;

	const $html = document.documentElement;

	const $triggers = document.querySelectorAll(selectors.trigger);
	if (!exist($triggers)) return;

	$triggers.forEach(($trigger) => {
		$trigger.addEventListener('mouseover', () => {
			$body.classList.add(classnames.bodyMenuOpen);
			$html.classList.add(classnames.htmlActiveState);
		});
		$trigger.addEventListener('mouseout', () => {
			$body.classList.remove(classnames.bodyMenuOpen);
			$html.classList.remove(classnames.htmlActiveState);
		});
	});

	const $menuTrigger = document.querySelector(selectors.menuTrigger);
	const $header = document.querySelector(selectors.header);
	if (!exist($header)) return;

	const $headerItems = document.querySelectorAll(selectors.headerItem);
	const $searchWrap = document.querySelector(selectors.searchWrap);
	const $searchInputMobile = document.querySelector(selectors.searchInputMobile);

	// search
	const $searchParent = document.querySelector(selectors.searchParent);
	const $searchInput = $searchParent?.querySelector(selectors.searchInput);
	const $searchTrigger = $searchParent?.querySelector(selectors.searchTrigger);
	const $headerBottomInfo = document.querySelector(selectors.headerBottomInfoBlock);

	document.addEventListener('click', (e) => {
		const targetElement = e.target;

		if (targetElement.closest(selectors.searchTrigger)) {
			$searchParent?.classList.add(classnames.formOpen);
			if ($searchInput) {
				setTimeout(() => {
					$searchInput.focus();
				}, 300); // time should be equals css transition
			}
		}
		if (!targetElement.closest(selectors.searchParent) && $searchParent?.classList.contains(classnames.formOpen)) {
			$searchParent?.classList.remove(classnames.formOpen);
			if ($searchInput) $searchInput.value = '';
		}
		if (targetElement.closest(selectors.menuTrigger)) {
			$menuTrigger.classList.toggle(classnames.menuTriggerState);
			$body.classList.toggle(classnames.bodyOpenMenuState);
		}
		if (targetElement.closest(selectors.headerBtn)) {
			targetElement.closest(`.${classnames.headerItem}`)?.classList.add(classnames.headerItemState);
			$headerBottomInfo.classList.add(classnames.headerBottomInfo);
		}
		if (targetElement.closest(selectors.headerBtnClose)) {
			targetElement.closest(`.${classnames.headerItem}`)?.classList.remove(classnames.headerItemState);
			$headerBottomInfo.classList.remove(classnames.headerBottomInfo);
		}
		if (targetElement.closest(selectors.searchBtn)) {
			$searchWrap?.classList.toggle(classnames.searchWrapState);
			if ($searchInputMobile) $searchInputMobile.focus();
		}
	});

	// Scroll

	let prevScrollPos = window.pageYOffset;

	function headerScroll() {
		if (prevScrollPos > 20 && !$header.classList.contains(classnames.headerScrollState)) {
			$header?.classList.add(classnames.headerScrollState);
		} else if (prevScrollPos < 20) {
			$header?.classList.remove(classnames.headerScrollState);
		} else if (prevScrollPos < window.pageYOffset) {
			$header?.classList.add(classnames.headerScrollPos);
		} else {
			$header?.classList.remove(classnames.headerScrollPos);
		}
		if (prevScrollPos > 80 && $searchWrap.classList.contains(classnames.searchWrapState)) {
			$searchWrap?.classList.remove(classnames.searchWrapState);
		}

		prevScrollPos = window.pageYOffset;
	}

	onWindowScroll(headerScroll);

	// header bar additional height
	let prevValue;
	const $bar = document.querySelector(selectors.headerBar);

	const calcAdditionalHeight = () => {
		if (exist($bar)) {
			const barHeight = $bar.offsetHeight;

			if (prevValue !== barHeight) {
				$header.style.setProperty('--aditional-height', `${barHeight}px`);
				prevValue = barHeight;
			}
		}
	};

	onWindowScroll(calcAdditionalHeight);
};

export default header;
